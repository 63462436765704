<template>
  <cx-badge :color="badgeColor">{{ badgeLabel }}</cx-badge>
</template>

<script>
import { CxBadge } from '~/components/cx';
import { SUBSCRIPTION_PLANS, SUBSCRIPTION_PLAN_TITLES } from '~/support/constants';

export const BADGE_COLOR_MAP = {
  [SUBSCRIPTION_PLANS.enterprise]: 'purple',
  [SUBSCRIPTION_PLANS.free]: 'darkerGray',
  [SUBSCRIPTION_PLANS.cruxForgeAnnual]: 'orange',
  [SUBSCRIPTION_PLANS.pro]: 'blue',
  [SUBSCRIPTION_PLANS.supplier]: 'gray',
  [SUBSCRIPTION_PLANS.trial]: 'green',
};

export default {
  name: 'SubscriptionBadge',

  components: { CxBadge },

  props: {
    plan: {
      default: '',
      type: String,
    },
  },

  computed: {
    badgeColor() {
      return BADGE_COLOR_MAP[this.plan];
    },

    badgeLabel() {
      return SUBSCRIPTION_PLAN_TITLES[this.plan];
    },
  },
};
</script>
